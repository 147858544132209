<template>
    <Panel v-if="user" :title="user.name ? user.name : '...'" :loading="loading" @closePanel="closePanel">
        <Tabs v-if="!loading" ref="tabs" class="tabs-w-100">
            <!-- User -->
            <Tab name="User" :selected="true">
                <div class="mb-2">
                    <Boolean v-model="user.enabled" textOn="Enabled" textOff="Disabled" class="me-1" />
                    <Boolean
                        v-model="user.store_id"
                        textOn="Linked with a store"
                        textOff="Not linked with a store"
                        class="me-1"
                    />
                    <Boolean
                        v-model="user.access_granted"
                        textOn="Access granted"
                        textOff="Access not granted"
                        class="me-1"
                    />
                    <Boolean
                        v-model="user.terms_confirmed"
                        textOn="Terms accepted"
                        textOff="Terms not accepted"
                        class="me-1"
                    />
                    <Boolean v-model="user.opt_in" textOn="Opted in" textOff="Opted out" />
                </div>
                <Sheet>
                    <Row name="First name" v-if="user.first_name">{{ user.first_name }}</Row>
                    <Row name="Last name" v-if="user.last_name">{{ user.last_name }}</Row>
                    <Row name="Birth date" v-if="user.birthdate">{{ user.birthdate | formatDate('DD/MM/YYYY') }}</Row>
                    <Row name="E-mail (login)" v-if="user.email">
                        <a :href="'mailto:' + user.email" class="me-1">{{ user.email }}</a>
                    </Row>
                    <Row name="Phone" v-if="user.phone"
                        ><a :href="'tel:' + user.phone">{{ user.phone }}</a></Row
                    >
                    <Row name="Address" v-if="user.address">
                        <Address :address="user.address" class="mb-1" />
                        <div>
                            <a :href="mapLink" target="_blank"><Icon icon="map-marker-alt" /> Map</a>
                        </div>
                    </Row>
                    <Row name="Locale">{{ user.locale }}</Row>
                    <Row name="Timezone">{{ user.timezone || '-' }}</Row>
                    <Row name="Store" v-if="user.store_id"><Store :id="user.store_id"></Store></Row>
                </Sheet>
                <Sheet>
                    <Row name="Last login"
                        ><span v-if="user.last_login">{{ user.last_login | formatDate }}</span
                        ><span v-else>Never</span></Row
                    >
                    <Row name="Created at">{{ user.created_at | formatDate }}</Row>
                    <Row name="Updated at">{{ user.updated_at | formatDate }}</Row>
                </Sheet>
            </Tab>

            <!-- Cards -->
            <Tab name="Cards" v-if="$store.getters.userHasAnyPermission(['showCards', 'manageCards'])">
                <Cards :params="{ user: user.id }" hideActions></Cards>
            </Tab>

            <!-- Expenses -->
            <Tab name="Expenses" v-if="$store.getters.userHasAnyPermission(['showExpenses', 'manageExpenses'])">
                <Expenses :params="{ user: user.id }" hideActions></Expenses>
            </Tab>

            <!-- Payment Methods -->
            <Tab
                name="Payment methods"
                v-if="$store.getters.userHasAnyPermission(['showPaymentMethods', 'managePaymentMethods'])"
            >
                <Tabs :pills="false" :small="true" ref="pmTabs">
                    <Tab name="Payment methods" :selected="true">
                        <PaymentMethods :user="user.id" ref="paymentMethods"></PaymentMethods>
                    </Tab>
                    <Tab name="Wallets">
                        <Wallets
                            :params="{ creditable_type: 'user', creditable_id: user.id }"
                            @submitted="fetchData"
                            ref="wallets"
                            hideFilters
                        ></Wallets>
                    </Tab>
                    <Tab name="Personal cards">
                        <Stat
                            :value="parseFloat(user.balance_overflow_authorized)"
                            legend="Maximum overflow amount"
                            money
                            decimals
                            class="mb-2"
                        />
                        <CreditCards :user="user.id" ref="overflow"></CreditCards>
                    </Tab>
                </Tabs>
            </Tab>

            <!-- Bookings -->
            <Tab name="Bookings" v-if="$store.getters.userHasAnyPermission(['showBookings', 'manageBookings'])">
                <Bookings :params="{ user: user.id }" hideActions></Bookings>
            </Tab>

            <!-- Groups of user -->
            <Tab name="Groups">
                <!-- Groups list -->
                <Datatable
                    name="Groups"
                    @search="fetchUserGroups"
                    :showPagination="false"
                    :loading="loadingUserGroups"
                    :hover="false"
                    v-if="$store.getters.userHasAnyPermission(['showGroups', 'manageGroups'])"
                >
                    <template v-slot:filters>
                        <Button
                            class="me-1"
                            @click="toggleFilterArchived"
                            :color="isArchived ? 'success' : 'secondary'"
                            small
                            >Archived</Button
                        >
                    </template>
                    <tr v-for="(group, index) in userGroups" :key="'UserGroup-' + index" valign="middle">
                        <td>
                            <code>{{ group.id }}</code>
                        </td>
                        <td>
                            <p class="m-0">
                                <a href="#" @click.prevent="openGroup(group)">{{ group.name }}</a>
                            </p>
                            <Badge
                                v-if="group.user_group && group.user_group.is_administrator"
                                class="mt-1"
                                color="info"
                                >Administrator</Badge
                            >
                        </td>
                        <td>
                            <Spinner v-if="loadingUserGroups" />
                            <RawOutput v-else-if="group.user_group" :item="group.user_group" />
                        </td>
                        <td class="text-end">
                            <a
                                v-if="!isArchived"
                                href="#"
                                is="confirm"
                                tag="a"
                                @confirm="removeUserFromGroup(group)"
                                title="Remove this user from this group?"
                                class="text-danger"
                                ><Icon icon="times" class="me-1" />Remove from group</a
                            >
                            <Badge v-else-if="group.user_group && group.user_group.deleted_at" color="warning"
                                >Archived on {{ group.user_group.deleted_at | formatDate('DD/MM/YYYY') }}</Badge
                            >
                        </td>
                    </tr>
                    <template v-slot:actions v-if="$store.getters.userHasPermission('managePartners')">
                        <Button color="secondary" small @click="openModal('updateGroups')" icon="plus" class="me-1"
                            >Add user to group</Button
                        >
                    </template>
                </Datatable>
            </Tab>

            <!-- Licenses -->
            <Tab name="Licenses" v-if="$store.getters.userHasAnyPermission(['showLicenses', 'manageLicenses'])">
                <Licenses :user="user.id" ref="licenses" />
            </Tab>

            <!-- Notifications -->
            <Tab name="Notifications" v-if="$store.getters.userHasAnyPermission(['showUsers', 'manageUsers'])">
                <Notifications :user="user.id" />
            </Tab>
        </Tabs>

        <div class="mt-4">
            <ID name="Hexeko" :value="user.id" class="me-1" />
            <ID
                v-for="(id, app) in user.external_id"
                :key="`external-id-${app}`"
                class="me-1"
                :name="app"
                :value="id"
                :url="external_ids[app] ? `${external_ids[app].url + id}` : ''"
            />
            <ID
                v-if="user.cognito_id"
                name="Cognito"
                :value="user.cognito_id"
                :url="
                    cognitoUserPoolId
                        ? `https://eu-west-3.console.aws.amazon.com/cognito/v2/idp/user-pools/${cognitoUserPoolId}/user-management/users/details/${user.cognito_id}`
                        : ''
                "
                class="me-1"
            />
        </div>
        <!-- Actions -->
        <template v-slot:actions>
            <!-- Dropdown -->
            <Dropdown small title="Actions" position="right">
                <DropdownItem
                    @click="openModal('updateUser')"
                    icon="user-edit"
                    v-if="$store.getters.userHasPermission('manageUsers')"
                    >Update user</DropdownItem
                >
                <DropdownItem
                    confirm
                    @confirm="enable"
                    icon="check-circle"
                    v-if="!user.enabled && $store.getters.userHasPermission('manageUsers')"
                    >Enable</DropdownItem
                >
                <DropdownItem
                    confirm
                    @confirm="disable"
                    icon="times-circle"
                    v-else-if="$store.getters.userHasPermission('manageUsers')"
                    >Disable</DropdownItem
                >
                <DropdownItem
                    @click="openModal('createWallet')"
                    icon="wallet"
                    v-if="$store.getters.userHasPermission('manageWallets')"
                    >Create wallet</DropdownItem
                >
                <DropdownItem
                    @click="openModal('issueCard')"
                    icon="credit-card"
                    v-if="$store.getters.userHasPermission('manageUsers')"
                    >Issue card</DropdownItem
                >
                <DropdownItem
                    @click="openModal('createLicense')"
                    icon="certificate"
                    v-if="$store.getters.userHasPermission('manageLicenses')"
                    >Create license</DropdownItem
                >
                <DropdownItem
                    @click="openModal('options')"
                    icon="cogs"
                    v-if="$store.getters.userHasPermission('manageUsers')"
                    >Options</DropdownItem
                >
                <DropdownItem
                    @click="openModal('permissions')"
                    icon="lock"
                    v-if="$store.getters.userHasPermission('managePrivileges')"
                    >Permissions</DropdownItem
                >
                <DropdownItem
                    @click="openModal('initialPassword')"
                    icon="lock"
                    v-if="$store.getters.userHasPermission('manageUsers')"
                    >Set initial password</DropdownItem
                >
                <DropdownItem
                    @click="openModal('WelcomeMail')"
                    icon="envelope"
                    v-if="$store.getters.userHasPermission('showUsers')"
                    >Send welcome mail</DropdownItem
                >
                <DropdownItem
                    confirm
                    @confirm="deleteUser"
                    class="text-danger"
                    icon="trash-alt"
                    v-if="$store.getters.userHasPermission('manageUsers')"
                    >Delete user</DropdownItem
                >
            </Dropdown>

            <!-- Update user -->
            <Modal v-if="isModalOpen('updateUser')" @close="closeModal('updateUser')" title="Update user">
                <UpdateUser :id="user.id" @submitted="userUpdated"></UpdateUser>
            </Modal>

            <!-- Update user group -->
            <Modal v-if="isModalOpen('updateGroups')" @close="closeModal('updateGroups')" title="Add group to user">
                <UpdateGroups :id="user.id" @submitted="groupsUpdated"></UpdateGroups>
            </Modal>

            <!-- Options -->
            <Modal v-if="isModalOpen('options')" @close="closeModal('options')" title="Options of user">
                <Options :id="user.id" @submitted="userUpdated"></Options>
            </Modal>

            <!-- Create wallet -->
            <Modal
                v-if="isModalOpen('createWallet')"
                @close="closeModal('createWallet')"
                title="Create wallet for user"
            >
                <CreateWallet :user="user.id" @submitted="walletCreated"></CreateWallet>
            </Modal>

            <!-- Issue card -->
            <Modal v-if="isModalOpen('issueCard')" @close="closeModal('issueCard')" title="Issue card for user">
                <IssueCard :user="user.id" @submitted="cardIssued"></IssueCard>
            </Modal>

            <!-- Create license -->
            <Modal
                v-if="isModalOpen('createLicense')"
                @close="closeModal('createLicense')"
                title="Create license for user"
            >
                <CreateLicense :user="user.id" @submitted="licenseCreated"></CreateLicense>
            </Modal>

            <!-- Permissions -->
            <Modal v-if="isModalOpen('permissions')" @close="closeModal('permissions')" title="Permissions of user">
                <Permissions :id="user.id" @submitted="userUpdated"></Permissions>
            </Modal>

            <!-- Set an initial password -->
            <Modal
                v-if="isModalOpen('initialPassword')"
                @close="closeModal('initialPassword')"
                title="Set an initial password"
            >
                <InitialPassword :user="user.id" />
            </Modal>

            <!-- Send Welcome Mail -->
            <Modal v-if="isModalOpen('WelcomeMail')" @close="closeModal('WelcomeMail')" title="Send a welcome mail">
                <WelcomeMail :user="user" @submitted="welcomeMailSended" />
            </Modal>
        </template>
    </Panel>
</template>

<script>
    import {
        Panel,
        Tabs,
        Tab,
        Datatable,
        Sheet,
        Row,
        Boolean,
        ID,
        Dropdown,
        DropdownItem,
        Alerts,
        Modal,
        Stat,
        Button,
        Spinner,
        Badge,
    } from '@tech_hexeko/design-system'
    import Bookings from '@/components/layout/Tabs/Bookings'
    import Expenses from '@/components/layout/Tabs/Expenses'
    import Cards from '@/components/layout/Tabs/Cards'
    import Licenses from '@/components/Licenses'
    import Notifications from '@/components/Notifications'
    import PaymentMethods from '@/components/PaymentMethods'
    import CreditCards from '@/components/CreditCards'
    import Store from '@/components/layout/Store'
    import Wallets from '@/components/Wallets'
    import Permissions from '@/components/Users/Permissions'
    import InitialPassword from '@/components/Users/InitialPassword'
    import WelcomeMail from '@/components/Users/WelcomeMail'
    import axios from 'axios'
    import Modals from '@tech_hexeko/design-system/src/mixins/Modals'
    import CreateWallet from '@/components/Users/CreateWallet'
    import IssueCard from '@/components/Users/IssueCard'
    import CreateLicense from '@/components/Users/CreateLicense'
    import UpdateUser from './UpdateUser'
    import Options from './Options'
    import UpdateGroups from './Groups'
    import RawOutput from '@/components/RawOutput'
    import Address from '@/components/Address'
    import { formatBoolean } from '@/utils/query'
    export default {
        name: 'UserPanel',
        components: {
            Address,
            Badge,
            Button,
            Panel,
            Tabs,
            Tab,
            Datatable,
            Sheet,
            Row,
            Boolean,
            Bookings,
            Expenses,
            Licenses,
            Notifications,
            ID,
            Store,
            Wallets,
            PaymentMethods,
            Dropdown,
            DropdownItem,
            Permissions,
            InitialPassword,
            Modal,
            CreateWallet,
            UpdateUser,
            Options,
            Cards,
            CreditCards,
            IssueCard,
            CreateLicense,
            UpdateGroups,
            Stat,
            WelcomeMail,
            Spinner,
            RawOutput,
        },
        mixins: [Modals],
        props: { id: Number },
        data() {
            return {
                loading: true,
                loadingUserGroups: true,
                user: {},
                cognitoUserPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID,
                wallets: [],
                external_ids: {
                    stripe: {
                        url: 'https://dashboard.stripe.com/',
                    },
                },
                userGroups: null,
                search: null,
                userDataInGroups: null,
                isArchived: false,
            }
        },
        computed: {
            mapLink: function () {
                return `https://www.google.com/maps?q=${this.user.address.address ?? this.user.address.line1} ${
                    this.user.address.zipcode
                } ${this.user.address.city} ${this.user.address.country}`
            },
        },
        methods: {
            fetchData() {
                this.loading = true
                // set requests
                const requestUser = this.$api.users.show(this.id, { includes: 'groups' })
                const requestWallets = this.$api.users.getWallets(this.id)

                // make requests
                axios.all([requestUser, requestWallets]).then(
                    axios.spread((...responses) => {
                        const responseUser = responses[0]
                        const responseWallets = responses[1]
                        // set data
                        this.user = responseUser
                        this.wallets = responseWallets.wallets

                        // fetch User Groups
                        this.fetchUserGroups()
                        this.loading = false
                    })
                )
            },
            fetchUserGroups(search) {
                this.search = search
                this.loadingUserGroups = true
                this.$api.users
                    .getGroups(this.user.id, {
                        is_active: formatBoolean(!this.isArchived),
                    })
                    .then((response) => {
                        this.userGroups = response.data
                    })
                    .catch((error) => {
                        if (error && error.response) Alerts.notificationError(error.response.data.message)
                    })
                    .finally(() => {
                        this.loadingUserGroups = false
                    })
            },
            deleteUser() {
                this.$api.users.delete(this.id).then(() => {
                    Alerts.notificationSuccess('Successfully deleted')
                    this.closePanel({ refresh: true })
                })
            },
            closePanel(payload) {
                this.$emit('closePanel', payload)
            },
            walletCreated() {
                this.fetchData()
                if (this.$refs['pmTabs']) {
                    this.$refs['pmTabs'].selectTab({ name: 'Wallets' })
                }
                this.closeModal('createWallet')
                this.fetchWallets()
            },
            cardIssued() {
                this.fetchData()
                if (this.$refs['pmTabs']) {
                    this.$refs['pmTabs'].selectTab({ name: 'Cards' })
                }
                this.closeModal('issueCard')
                this.fetchCards()
            },
            licenseCreated() {
                if (this.$refs['tabs']) this.$refs['tabs'].selectTab({ name: 'Licenses' })
                if (this.$refs['licenses']) this.$refs['licenses'].fetchData()
                this.closeModal('createLicense')
            },
            welcomeMailSended() {
                this.closeModal('WelcomeMail')
            },
            userUpdated() {
                this.fetchData()
                this.closeModal('updateUser')
                this.closeModal('permissions')
            },
            groupsUpdated() {
                this.fetchData()
                this.closeModal('updateGroups')
                this.fetchGroups()
            },
            fetchGroups() {
                if (this.$refs['groups']) {
                    this.$refs['groups'].fetchData()
                }
            },
            fetchCards() {
                if (this.$refs['cards']) {
                    this.$refs['cards'].fetchData()
                }
            },
            fetchWallets() {
                if (this.$refs['wallets']) {
                    this.$refs['wallets'].fetchData()
                }
            },
            enable() {
                this.$api.users.enable(this.id).then(() => {
                    Alerts.notificationSuccess('Successfully enabled')
                    this.closePanel({ refresh: true })
                })
            },
            disable() {
                this.$api.users.disable(this.id).then(() => {
                    Alerts.notificationSuccess('Successfully disabled')
                    this.closePanel({ refresh: true })
                })
            },
            openGroup(group) {
                this.openPanel('GroupPanel', group.id)
            },
            removeUserFromGroup(group) {
                this.$api.groups
                    .removeUser(group.id, {
                        user_id: this.user.id,
                    })
                    .then(() => {
                        this.fetchData()
                    })
                    .catch((error) => {
                        let errorTxt =
                            error && error.response
                                ? error.response.data.message
                                : 'An error has occurred, impossible to remove user from group'
                        Alerts.notificationError(errorTxt)
                    })
            },
            toggleFilterArchived() {
                this.isArchived = !this.isArchived
                this.fetchUserGroups(this.search)
            },
        },
        mounted() {
            this.fetchData()
        },
    }
</script>

<style type="text/css">
    .tabs-w-100 > ul.nav {
        width: 100%;
    }
</style>
